import { get } from "utils/BaseRequest";

export type APIResponse<T> = {
  docs: T;
  totalDocs: number;
  limit: number;
  totalPages: number;
  page: number;
  pagingCounter: number;
  hasPrevPage: boolean;
  hasNextPage: boolean;
  prevPage: number | null;
  nextPage: number;
};

export interface Distribution {
  _id: string;
  createdAt: string;
  updatedAt: string;
  mint: string;
  status: string;
  totalHarvestGasFees: string;
  __v: number;
  totalDistributionGasFees: string;
  totalDistributionHolders: number;
  totalAccumulatedFees: string;
  adjustedTotalRewards?: string;
  errorReason?: string;
  id: string;
  transactionHash?: string;
}

export interface DistributionJobData {
  user: string;
  amount: string;
  _id: string;
}

export interface DistributionJob {
  _id: string;
  distributionId: string;
  mint: string;
  data: DistributionJobData[];
  status: string;
  createdAt: string;
  updatedAt: string;
  __v: number;
  transactionHash: string;
  id: string;
  errorReason?: string;
}

export interface HarvestJob {
  _id: string;
  mint: string;
  sources: string[];
  status: string;
  retryCount: number;
  createdAt: string;
  updatedAt: string;
  __v: number;
  transactionHash: string;
  id: string;
  errorReason?: string;
}

interface DecodeTransferPayload {
  cursor: number;
  initialBlock: number;
  latestBlock: number;
  isDone: boolean;
  lastMonitorAt: number;
}

interface MonitorPayload {
  lastMonitorAt: number;
}

interface MonitorTxLatest {
  signature: string;
  block: number;
  timestamp: number;
}

interface MonitorTxPayload extends MonitorPayload {
  programId: string;
  before: string | null;
  until: string | null;
  latest: MonitorTxLatest;
  oldest: MonitorTxLatest;
  groupId: string | null;
  groupIndex: number | null;
}

interface DistributionTracker {
  totalAccumulatedRewards: number;
  totalFailedDistributionRewards: number;
  totalDistributionCount: number;
}

export interface MintConfig {
  _id: string;
  mint: string;
  creator: string;
  name: string;
  symbol: string;
  uri: string;
  description: string;
  decimals: number;
  authority: string;
  rewardMint: string;
  rewardMintDecimals: number;
  ownerFeeBps: number;
  platformFeeBps: number;
  createdAt: string;
  updatedAt: string;
  status: number;
  __v: number;
  decodeTransferPayload: DecodeTransferPayload;
  monitorConvertPayload: MonitorPayload;
  monitorHarvestPayload: MonitorPayload;
  monitorTxPayload: MonitorTxPayload;
  lastTransferAt: number;
  distributionTracker?: DistributionTracker;
  id: string;
  "distributionMetrics"?: {
    "24h"?: {
      rewards: number;
      total: number;
      pending: number;
      completed: number;
      failed: number;
    }
  },
  "harvestMetrics"?: {
    "24h"?: {
      total: number;
      pending: number;
      completed: number;
      failed: number;
    };
  };
}

interface GetDistributionsParams {
  page: number;
  limit: number;
  search?: string;
  status?: string;
  mint?: string;
}

// const baseUrl = "http://localhost:3101/v1/admin";
const baseUrl = "/manager/baby-token";


export async function getDistributions(params: GetDistributionsParams) {
  const result = await get(`${baseUrl}/distributions`, params, {
    isRawUrl: true,
  });
  const data = result?.["data"]
  return data as APIResponse<Distribution[]>;
}

// Get distribution job
interface GetDistributionJobsParams {
  page: number;
  limit: number;
  search?: string;
  status?: string;
  mint?: string;
  distributionId?: string;
}
export async function getDistributionJobs(params: GetDistributionJobsParams) {
  const result = await get(`${baseUrl}/distributions/jobs`, params, {
    isRawUrl: true,
  });
  const data = result?.["data"]
  return data as APIResponse<DistributionJob[]>;
}


// harvest-jobs
interface GetHarvestJobsParams {
  page: number;
  limit: number;
  search?: string;
  status?: string;
  mint?: string;
}
export async function getHarvestJobs(params: GetHarvestJobsParams) {
  const result = await get(`${baseUrl}/harvest-jobs`, params, {
    isRawUrl: true,
  });
  const data = result?.["data"]
  return data as APIResponse<HarvestJob[]>;
}

// mint-config
interface GetMintConfigParams {
  page: number;
  limit: number;
  search?: string;
  status?: string;
}
export async function getMintConfigs(params: GetMintConfigParams) {
  const result = await get(`${baseUrl}/mint-config`, params, {
    isRawUrl: true,
  });

  return result?.["data"] as APIResponse<MintConfig[]>;
}

// get mint config by mint
export async function getMintConfigByMint(mint: string) {
  const result = await get(`${baseUrl}/mint-config/by-mint`, {
    mint,
  }, {
    isRawUrl: true,
  });
  const data = result?.["data"]
  return data as MintConfig;
}