import {
  Box,
  Button,
  Flex,
  Input,
  InputGroup,
  InputLeftElement,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  useDisclosure,
  Select,
  HStack,
  Text,
  IconButton,
  useToast,
  Spinner,
  Center,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  ModalFooter,
  VStack,
  Grid,
  GridItem,
  Badge,
  Heading,
  Divider,
  Tooltip,
} from "@chakra-ui/react";
import {
  SearchIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from "@chakra-ui/icons";
import { useEffect, useState } from "react";
import { useParams, useHistory, useLocation } from "react-router-dom";
import {
  getMintConfigByMint,
  getDistributions,
  getDistributionJobs,
  getHarvestJobs,
  MintConfig,
  Distribution,
  DistributionJob,
  HarvestJob,
} from "utils/apiService/BabyToken";
import { subAddress } from "utils/helpers";

enum Status {
  PENDING = 0,
  ACTIVE = 1,
  JUPITER_DELISTED = 2,
  NOT_EXIST = 3,
  BLACKLISTED = 4,
}

function getMintFromUrl(url: string) {
  const regex = /\/admin\/baby-token-detail\/([a-zA-Z0-9]+)/;
  const match = url.match(regex);
  return match ? match[1] : null;
}

// day, hour, minute ago
export function getTimeAgo(timestamp: number) {
  const now = new Date();
  const diff = now.getTime() - timestamp;
  const diffDays = Math.floor(diff / (1000 * 60 * 60 * 24));
  const diffHours = Math.floor(diff / (1000 * 60 * 60)) % 60;
  const diffMinutes = Math.floor(diff / (1000 * 60)) % 60;
  const diffSeconds = Math.floor(diff / 1000) % 60;
  if (diffDays > 0) {
    return `${diffDays}d${diffHours > 0 ? ` ${diffHours}h` : ""} ago`;
  }

  if (diffHours > 0) {
    return `${diffHours}h${diffMinutes > 0 ? ` ${diffMinutes}m` : ""} ago`;
  }

  return `${diffMinutes}m${diffSeconds > 0 ? ` ${diffSeconds}s` : ""} ago`;
}
const BabyTokenDetail = () => {
  // const { mint } = useParams<{ mint: string }>();
  // console.log("mint", mint);
  // Extract mint from url: http://localhost:3000/admin/baby-token-detail/5hVksvtJrBxAAN95TtxnJ37N3Y6pQBkfg3pNZWmYfYUv by regex
  const mint = getMintFromUrl(window.location.href);

  const history = useHistory();
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedData, setSelectedData] = useState<any>(null);
  const [mintConfig, setMintConfig] = useState<MintConfig | null>(null);
  const [distributions, setDistributions] = useState<Distribution[]>([]);
  const [distributionJobs, setDistributionJobs] = useState<DistributionJob[]>(
    []
  );
  const [harvestJobs, setHarvestJobs] = useState<HarvestJob[]>([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [search, setSearch] = useState("");
  const [status, setStatus] = useState<string>("");

  // Filter states using objects
  const [filters, setFilters] = useState({
    distributions: {
      page: 1,
      search: "",
      status: "",
      totalPages: 1,
    },
    distributionJobs: {
      page: 1,
      search: "",
      status: "",
      totalPages: 1,
    },
    harvestJobs: {
      page: 1,
      search: "",
      status: "",
      totalPages: 1,
    },
  });

  const fetchMintConfig = async () => {
    try {
      setLoading(true);
      const response = await getMintConfigByMint(mint);
      if (response) {
        setMintConfig(response);
      } else {
        toast({
          title: "Error",
          description: "Mint config not found",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
        // history.push("/admin/baby-token");
      }
    } catch (error) {
      toast({
        title: "Error",
        description: "Failed to fetch mint config",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      // history.push("/admin/baby-token");
    } finally {
      setLoading(false);
    }
  };

  const fetchDistributions = async () => {
    try {
      setLoading(true);
      const response = await getDistributions({
        page: filters.distributions.page,
        limit: 10,
        search: filters.distributions.search,
        status: filters.distributions.status,
        mint,
      });
      setDistributions(response?.docs || []);
      setFilters((prev) => ({
        ...prev,
        distributions: {
          ...prev.distributions,
          totalPages: response?.totalPages || 1,
        },
      }));
    } catch (error) {
      toast({
        title: "Error",
        description: "Failed to fetch distributions",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };

  const fetchDistributionJobs = async () => {
    try {
      setLoading(true);
      const response = await getDistributionJobs({
        page: filters.distributionJobs.page,
        limit: 10,
        search: filters.distributionJobs.search,
        status: filters.distributionJobs.status,
        mint,
      });
      setDistributionJobs(response?.docs || []);
      setFilters((prev) => ({
        ...prev,
        distributionJobs: {
          ...prev.distributionJobs,
          totalPages: response?.totalPages || 1,
        },
      }));
    } catch (error) {
      toast({
        title: "Error",
        description: "Failed to fetch distribution jobs",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };

  const fetchHarvestJobs = async () => {
    try {
      setLoading(true);
      const response = await getHarvestJobs({
        page: filters.harvestJobs.page,
        limit: 10,
        search: filters.harvestJobs.search,
        status: filters.harvestJobs.status,
        mint,
      });
      setHarvestJobs(response?.docs || []);
      setFilters((prev) => ({
        ...prev,
        harvestJobs: {
          ...prev.harvestJobs,
          totalPages: response?.totalPages || 1,
        },
      }));
    } catch (error) {
      toast({
        title: "Error",
        description: "Failed to fetch harvest jobs",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (mint) {
      fetchMintConfig();
    }
  }, [mint]);

  useEffect(() => {
    if (mint) {
      fetchDistributions();
    }
  }, [
    filters.distributions.page,
    filters.distributions.search,
    filters.distributions.status,
    mint,
  ]);

  useEffect(() => {
    if (mint) {
      fetchDistributionJobs();
    }
  }, [
    filters.distributionJobs.page,
    filters.distributionJobs.search,
    filters.distributionJobs.status,
    mint,
  ]);

  useEffect(() => {
    if (mint) {
      fetchHarvestJobs();
    }
  }, [
    filters.harvestJobs.page,
    filters.harvestJobs.search,
    filters.harvestJobs.status,
    mint,
  ]);

  const handleCopy = (text: string) => {
    navigator.clipboard.writeText(text);
    toast({
      title: "Copied",
      description: "Address copied to clipboard",
      status: "success",
      duration: 2000,
      isClosable: true,
    });
  };

  const handleViewJson = (data: any) => {
    setSelectedData(data);
    onOpen();
  };

  const getStatusLabel = (status: number) => {
    switch (status) {
      case Status.PENDING:
        return "Pending";
      case Status.ACTIVE:
        return "Active";
      case Status.JUPITER_DELISTED:
        return "Jupiter Delisted";
      case Status.NOT_EXIST:
        return "Not Exist";
      case Status.BLACKLISTED:
        return "Blacklisted";
      default:
        return "Unknown";
    }
  };

  const calculateTotalRewards = (jobs: any[] = []) => {
    return jobs.reduce((sum, job) => {
      return sum + Number(job?.amount || 0);
    }, 0);
  };

  if (loading && !mintConfig) {
    return (
      <Center h="100vh">
        <Spinner size="xl" />
      </Center>
    );
  }

  if (!mintConfig) {
    return (
      <Center h="100vh">
        <Text>No data found</Text>
      </Center>
    );
  }

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <Button mb={4} onClick={() => history.goBack()}>
        Back
      </Button>

      {/* Mint Config Info */}
      <Box mb={8}>
        <Heading size="lg" mb={4}>
          Mint Config Information
        </Heading>
        <Grid templateColumns="repeat(2, 1fr)" gap={4}>
          <GridItem>
            <VStack align="start" spacing={2}>
              <Text>
                <strong>Mint:</strong>{" "}
                <Text
                  as="span"
                  cursor="copy"
                  onClick={() => handleCopy(mintConfig.mint)}
                  _hover={{ color: "blue.500" }}
                >
                  {subAddress(mintConfig.mint)}
                </Text>
              </Text>
              <Text>
                <strong>Creator:</strong>{" "}
                <Text
                  as="span"
                  cursor="copy"
                  onClick={() => handleCopy(mintConfig.creator)}
                  _hover={{ color: "blue.500" }}
                >
                  {subAddress(mintConfig.creator)}
                </Text>
              </Text>
              <Text>
                <strong>Name:</strong> {mintConfig.name}
              </Text>
              <Text>
                <strong>Symbol:</strong> {mintConfig.symbol}
              </Text>
              <Text>
                <strong>Status:</strong>{" "}
                <Badge
                  colorScheme={
                    mintConfig.status === Status.ACTIVE
                      ? "green"
                      : mintConfig.status === Status.BLACKLISTED
                      ? "red"
                      : "yellow"
                  }
                >
                  {getStatusLabel(mintConfig.status)}
                </Badge>
              </Text>
              <Box w="100%" overflowX="auto">
                <Table variant="simple" size="sm">
                  <Thead>
                    <Tr>
                      <Th>Monitor Type</Th>
                      <Th>Last Check</Th>
                      <Th>Time Ago</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {/* Last Transfer at */}
                    <Tr>
                      <Td>Last Transfer</Td>
                      <Td>
                        {mintConfig.lastTransferAt
                          ? new Date(
                              mintConfig.lastTransferAt * 1000
                            ).toLocaleString()
                          : "N/A"}
                      </Td>
                      <Td>
                        {mintConfig.lastTransferAt
                          ? getTimeAgo(mintConfig.lastTransferAt * 1000)
                          : "N/A"}
                      </Td>
                    </Tr>
                    <Tr>
                      <Td>Transfer Monitor</Td>
                      <Td>
                        {mintConfig?.decodeTransferPayload?.lastMonitorAt
                          ? new Date(
                              mintConfig.decodeTransferPayload.lastMonitorAt *
                                1000
                            ).toLocaleString()
                          : "N/A"}
                      </Td>
                      <Td>
                        {mintConfig?.decodeTransferPayload?.lastMonitorAt
                          ? getTimeAgo(
                              mintConfig.decodeTransferPayload.lastMonitorAt *
                                1000
                            )
                          : "N/A"}
                      </Td>
                    </Tr>
                    <Tr>
                      <Td>Convert Monitor</Td>
                      <Td>
                        {mintConfig?.monitorConvertPayload?.lastMonitorAt
                          ? new Date(
                              mintConfig.monitorConvertPayload.lastMonitorAt *
                                1000
                            ).toLocaleString()
                          : "N/A"}
                      </Td>
                      <Td>
                        {mintConfig?.monitorConvertPayload?.lastMonitorAt
                          ? getTimeAgo(
                              mintConfig.monitorConvertPayload.lastMonitorAt *
                                1000
                            )
                          : "N/A"}
                      </Td>
                    </Tr>
                    <Tr>
                      <Td>Harvest Monitor</Td>
                      <Td>
                        {mintConfig?.monitorHarvestPayload?.lastMonitorAt
                          ? new Date(
                              mintConfig.monitorHarvestPayload.lastMonitorAt *
                                1000
                            ).toLocaleString()
                          : "N/A"}
                      </Td>
                      <Td>
                        {mintConfig?.monitorHarvestPayload?.lastMonitorAt
                          ? getTimeAgo(
                              mintConfig.monitorHarvestPayload.lastMonitorAt *
                                1000
                            )
                          : "N/A"}
                      </Td>
                    </Tr>
                    <Tr>
                      <Td>History Transactions</Td>
                      <Td>
                        {mintConfig?.monitorTxPayload?.lastMonitorAt
                          ? new Date(
                              mintConfig.monitorTxPayload.lastMonitorAt * 1000
                            ).toLocaleString()
                          : "N/A"}
                      </Td>
                      <Td>
                        {mintConfig?.monitorTxPayload?.lastMonitorAt
                          ? getTimeAgo(
                              mintConfig.monitorTxPayload.lastMonitorAt * 1000
                            )
                          : "N/A"}
                      </Td>
                    </Tr>
                  </Tbody>
                </Table>
              </Box>
              {mintConfig.distributionTracker && (
                <Box w="100%" overflowX="auto" mt={4}>
                  <Table variant="simple" size="sm">
                    <Thead>
                      <Tr>
                        <Th>Metric</Th>
                        <Th>Value</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      <Tr>
                        <Td>Total Accumulated Rewards</Td>
                        <Td>
                          {
                            +Number(
                              (Number(
                                mintConfig?.distributionTracker
                                  ?.totalAccumulatedRewards
                              ) || 0) /
                                10 ** 9
                            ).toFixed(18)
                          }{" "}
                          SOL
                        </Td>
                      </Tr>
                      <Tr>
                        <Td>Total Failed Distribution Rewards</Td>
                        <Td>
                          {
                            +Number(
                              (Number(
                                mintConfig?.distributionTracker
                                  ?.totalFailedDistributionRewards
                              ) || 0) /
                                10 ** 9
                            ).toFixed(18)
                          }{" "}
                          SOL
                        </Td>
                      </Tr>
                      <Tr>
                        <Td>Total Distribution Count</Td>
                        <Td>
                          {
                            mintConfig.distributionTracker
                              .totalDistributionCount
                          }
                        </Td>
                      </Tr>
                    </Tbody>
                  </Table>
                </Box>
              )}
            </VStack>
          </GridItem>
          <GridItem>
            <VStack align="start" spacing={2}>
              <Text>
                <strong>Created At:</strong>{" "}
                {new Date(mintConfig.createdAt).toLocaleString()}
              </Text>
              <Text>
                <strong>Last Transfer:</strong>{" "}
                {mintConfig.lastTransferAt
                  ? new Date(
                      mintConfig.lastTransferAt * 1000
                    ).toLocaleString() +
                    ` (${getTimeAgo(mintConfig.lastTransferAt * 1000)})`
                  : "N/A"}
              </Text>
              <Text>
                <strong>Decimals:</strong> {mintConfig.decimals}
              </Text>
              <Text>
                <strong>Owner Fee BPS:</strong> {mintConfig.ownerFeeBps}
              </Text>
              <Text>
                <strong>Platform Fee BPS:</strong> {mintConfig.platformFeeBps}
              </Text>
            </VStack>
          </GridItem>
        </Grid>
      </Box>

      <Divider mb={8} />

      {/* Distributions Table */}
      <Box mb={8}>
        <Heading size="md" mb={4}>
          Distributions
        </Heading>
        <Flex mb={4}>
          <InputGroup maxW="300px">
            <InputLeftElement pointerEvents="none">
              <SearchIcon color="gray.300" />
            </InputLeftElement>
            <Input
              placeholder="Search"
              value={filters.distributions.search}
              onChange={(e) =>
                setFilters((prev) => ({
                  ...prev,
                  distributions: {
                    ...prev.distributions,
                    search: e.target.value,
                  },
                }))
              }
            />
          </InputGroup>
          <Select
            placeholder="All Status"
            value={filters.distributions.status}
            onChange={(e) =>
              setFilters((prev) => ({
                ...prev,
                distributions: {
                  ...prev.distributions,
                  status: e.target.value,
                },
              }))
            }
            maxW="200px"
            ml={4}
          >
            <option value="pending">Pending</option>
            <option value="processing">Processing</option>
            <option value="completed">Completed</option>
            <option value="failed">Failed</option>
            <option value="rejected">Rejected</option>
          </Select>
        </Flex>
        <Box overflowX="auto">
          <Table variant="simple">
            <Thead>
              <Tr>
                <Th>ID</Th>
                <Th>Status</Th>
                <Th>Holders</Th>
                <Th>Fees</Th>
                <Th>Rewards</Th>
                <Th>Hash</Th>
                <Th>Created</Th>
                <Th>Actions</Th>
              </Tr>
            </Thead>
            <Tbody>
              {distributions.map((dist) => (
                <Tr key={dist._id}>
                  <Td>{subAddress(dist._id)}</Td>
                  <Td>
                    <Tooltip
                      label={dist.errorReason || "No error"}
                      isDisabled={!dist.errorReason}
                    >
                      <Badge
                        colorScheme={
                          dist.status === "completed"
                            ? "green"
                            : dist.status === "failed"
                            ? "red"
                            : "yellow"
                        }
                        cursor={dist.errorReason ? "help" : "default"}
                      >
                        {dist.status}
                      </Badge>
                    </Tooltip>
                  </Td>
                  <Td>{dist.totalDistributionHolders || 0}</Td>
                  <Td>
                    {mintConfig
                      ? +Number(
                          (Number(dist.totalAccumulatedFees) || 0) / 10 ** 9
                        ).toFixed(2) +
                          " " +
                          mintConfig?.symbol || "N/A"
                      : "N/A"}
                  </Td>
                  <Td>
                    {
                      +Number(
                        (Number(dist.adjustedTotalRewards) || 0) / 10 ** 9
                      ).toFixed(4)
                    }{" "}
                    SOL
                  </Td>
                  <Td>
                    {dist.transactionHash ? (
                      <Text
                        cursor="copy"
                        onClick={() => handleCopy(dist.transactionHash)}
                        _hover={{ color: "blue.500" }}
                      >
                        {subAddress(dist.transactionHash)}
                      </Text>
                    ) : (
                      <Text color="gray.500">N/A</Text>
                    )}
                  </Td>
                  <Td>{new Date(dist.createdAt).toLocaleString()}</Td>
                  <Td>
                    <Button
                      size="sm"
                      colorScheme="green"
                      onClick={() => handleViewJson(dist)}
                    >
                      View JSON
                    </Button>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </Box>
        <Flex justify="center" mt={4}>
          <HStack spacing={2}>
            <IconButton
              aria-label="Previous page"
              icon={<ChevronLeftIcon />}
              onClick={() =>
                setFilters((prev) => ({
                  ...prev,
                  distributions: {
                    ...prev.distributions,
                    page: Math.max(1, prev.distributions.page - 1),
                  },
                }))
              }
              isDisabled={filters.distributions.page === 1}
            />
            <Text>
              Page {filters.distributions.page} of{" "}
              {filters.distributions.totalPages}
            </Text>
            <IconButton
              aria-label="Next page"
              icon={<ChevronRightIcon />}
              onClick={() =>
                setFilters((prev) => ({
                  ...prev,
                  distributions: {
                    ...prev.distributions,
                    page: Math.min(
                      prev.distributions.totalPages,
                      prev.distributions.page + 1
                    ),
                  },
                }))
              }
              isDisabled={
                filters.distributions.page === filters.distributions.totalPages
              }
            />
          </HStack>
        </Flex>
      </Box>

      {/* Distribution Jobs Table */}
      <Box mb={8}>
        <Heading size="md" mb={4}>
          Distribution Jobs
        </Heading>
        <Flex mb={4}>
          <InputGroup maxW="300px">
            <InputLeftElement pointerEvents="none">
              <SearchIcon color="gray.300" />
            </InputLeftElement>
            <Input
              placeholder="Search"
              value={filters.distributionJobs.search}
              onChange={(e) =>
                setFilters((prev) => ({
                  ...prev,
                  distributionJobs: {
                    ...prev.distributionJobs,
                    search: e.target.value,
                  },
                }))
              }
            />
          </InputGroup>
          <Select
            placeholder="All Status"
            value={filters.distributionJobs.status}
            onChange={(e) =>
              setFilters((prev) => ({
                ...prev,
                distributionJobs: {
                  ...prev.distributionJobs,
                  status: e.target.value,
                },
              }))
            }
            maxW="200px"
            ml={4}
          >
            <option value="pending">Pending</option>
            <option value="processing">Processing</option>
            <option value="completed">Completed</option>
            <option value="failed">Failed</option>
            <option value="rejected">Rejected</option>
          </Select>
        </Flex>
        <Box overflowX="auto">
          <Table variant="simple">
            <Thead>
              <Tr>
                <Th>ID</Th>
                <Th>Status</Th>
                <Th>Holders Count</Th>
                <Th>Rewards</Th>
                <Th>Transaction Hash</Th>
                <Th>Created At</Th>
                <Th>Actions</Th>
              </Tr>
            </Thead>
            <Tbody>
              {distributionJobs.map((job) => (
                <Tr key={job._id}>
                  <Td>{subAddress(job._id)}</Td>
                  <Td>
                    <Tooltip
                      label={job.errorReason || "No error"}
                      isDisabled={!job.errorReason}
                    >
                      <Badge
                        colorScheme={
                          job.status === "completed"
                            ? "green"
                            : job.status === "failed"
                            ? "red"
                            : "yellow"
                        }
                        cursor={job.errorReason ? "help" : "default"}
                      >
                        {job.status}
                      </Badge>
                    </Tooltip>
                  </Td>
                  <Td>{job?.data?.length || 0}</Td>
                  <Td>
                    <Text color="green.500">
                      {
                        +Number(
                          calculateTotalRewards(job.data) / 10 ** 9
                        ).toFixed(9)
                      }{" "}
                      SOL
                    </Text>
                  </Td>
                  <Td>
                    {job.transactionHash ? (
                      <Text
                        cursor="copy"
                        onClick={() => handleCopy(job.transactionHash)}
                        _hover={{ color: "blue.500" }}
                      >
                        {subAddress(job.transactionHash)}
                      </Text>
                    ) : (
                      <Text color="gray.500">N/A</Text>
                    )}
                  </Td>
                  <Td>{new Date(job.createdAt).toLocaleString()}</Td>
                  <Td>
                    <Button
                      size="sm"
                      colorScheme="green"
                      onClick={() => handleViewJson(job)}
                    >
                      View JSON
                    </Button>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </Box>
        <Flex justify="center" mt={4}>
          <HStack spacing={2}>
            <IconButton
              aria-label="Previous page"
              icon={<ChevronLeftIcon />}
              onClick={() =>
                setFilters((prev) => ({
                  ...prev,
                  distributionJobs: {
                    ...prev.distributionJobs,
                    page: Math.max(1, prev.distributionJobs.page - 1),
                  },
                }))
              }
              isDisabled={filters.distributionJobs.page === 1}
            />
            <Text>
              Page {filters.distributionJobs.page} of{" "}
              {filters.distributionJobs.totalPages}
            </Text>
            <IconButton
              aria-label="Next page"
              icon={<ChevronRightIcon />}
              onClick={() =>
                setFilters((prev) => ({
                  ...prev,
                  distributionJobs: {
                    ...prev.distributionJobs,
                    page: Math.min(
                      prev.distributionJobs.totalPages,
                      prev.distributionJobs.page + 1
                    ),
                  },
                }))
              }
              isDisabled={
                filters.distributionJobs.page ===
                filters.distributionJobs.totalPages
              }
            />
          </HStack>
        </Flex>
      </Box>

      {/* Harvest Jobs Table */}
      <Box mb={8}>
        <Heading size="md" mb={4}>
          Harvest Jobs
        </Heading>
        <Flex mb={4}>
          <InputGroup maxW="300px">
            <InputLeftElement pointerEvents="none">
              <SearchIcon color="gray.300" />
            </InputLeftElement>
            <Input
              placeholder="Search"
              value={filters.harvestJobs.search}
              onChange={(e) =>
                setFilters((prev) => ({
                  ...prev,
                  harvestJobs: {
                    ...prev.harvestJobs,
                    search: e.target.value,
                  },
                }))
              }
            />
          </InputGroup>
          <Select
            placeholder="All Status"
            value={filters.harvestJobs.status}
            onChange={(e) =>
              setFilters((prev) => ({
                ...prev,
                harvestJobs: {
                  ...prev.harvestJobs,
                  status: e.target.value,
                },
              }))
            }
            maxW="200px"
            ml={4}
          >
            <option value="pending">Pending</option>
            <option value="processing">Processing</option>
            <option value="completed">Completed</option>
            <option value="failed">Failed</option>
            <option value="rejected">Rejected</option>
          </Select>
        </Flex>
        <Box overflowX="auto">
          <Table variant="simple">
            <Thead>
              <Tr>
                <Th>ID</Th>
                <Th>Status</Th>
                <Th>Retry Count</Th>
                <Th>Transaction Hash</Th>
                <Th>Created At</Th>
                <Th>Actions</Th>
              </Tr>
            </Thead>
            <Tbody>
              {harvestJobs.map((job) => (
                <Tr key={job._id}>
                  <Td>{subAddress(job._id)}</Td>
                  <Td>
                    <Tooltip
                      label={job.errorReason || "No error"}
                      isDisabled={!job.errorReason}
                    >
                      <Badge
                        colorScheme={
                          job.status === "completed"
                            ? "green"
                            : job.status === "failed"
                            ? "red"
                            : "yellow"
                        }
                        cursor={job.errorReason ? "help" : "default"}
                      >
                        {job.status}
                      </Badge>
                    </Tooltip>
                  </Td>
                  <Td>{job.retryCount}</Td>
                  <Td>
                    {job.transactionHash ? (
                      <Text
                        cursor="copy"
                        onClick={() => handleCopy(job.transactionHash)}
                        _hover={{ color: "blue.500" }}
                      >
                        {subAddress(job.transactionHash)}
                      </Text>
                    ) : (
                      <Text color="gray.500">N/A</Text>
                    )}
                  </Td>
                  <Td>{new Date(job.createdAt).toLocaleString()}</Td>
                  <Td>
                    <Button
                      size="sm"
                      colorScheme="green"
                      onClick={() => handleViewJson(job)}
                    >
                      View JSON
                    </Button>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </Box>
        <Flex justify="center" mt={4}>
          <HStack spacing={2}>
            <IconButton
              aria-label="Previous page"
              icon={<ChevronLeftIcon />}
              onClick={() =>
                setFilters((prev) => ({
                  ...prev,
                  harvestJobs: {
                    ...prev.harvestJobs,
                    page: Math.max(1, prev.harvestJobs.page - 1),
                  },
                }))
              }
              isDisabled={filters.harvestJobs.page === 1}
            />
            <Text>
              Page {filters.harvestJobs.page} of{" "}
              {filters.harvestJobs.totalPages}
            </Text>
            <IconButton
              aria-label="Next page"
              icon={<ChevronRightIcon />}
              onClick={() =>
                setFilters((prev) => ({
                  ...prev,
                  harvestJobs: {
                    ...prev.harvestJobs,
                    page: Math.min(
                      prev.harvestJobs.totalPages,
                      prev.harvestJobs.page + 1
                    ),
                  },
                }))
              }
              isDisabled={
                filters.harvestJobs.page === filters.harvestJobs.totalPages
              }
            />
          </HStack>
        </Flex>
      </Box>

      {/* JSON Modal */}
      <Modal isOpen={isOpen} onClose={onClose} size="2xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Raw JSON Data</ModalHeader>
          <ModalCloseButton />
          <ModalBody fontSize="sm">
            <Box
              p={4}
              bg="gray.50"
              borderRadius="md"
              maxH="70vh"
              overflowY="auto"
            >
              <pre style={{ whiteSpace: "pre-wrap", wordWrap: "break-word" }}>
                {JSON.stringify(selectedData, null, 2)}
              </pre>
            </Box>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={onClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default BabyTokenDetail;
