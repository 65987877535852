import axios, {
  AxiosRequestConfig,
  AxiosResponse,
  AxiosInstance,
  AxiosError,
} from "axios";
import { createStandaloneToast } from "@chakra-ui/react";

import constants from "configs/constants";

const toast = createStandaloneToast();

const getUrlPrefix = (isRawUrl?: boolean): string => {
  return isRawUrl ? "" : "/manager";
}

const options: AxiosRequestConfig<any> = {
  baseURL: process.env.REACT_APP_BASE_API_URL,
};

const instance: AxiosInstance = axios.create(options);

instance.interceptors.request.use(
  (config: any) => config,
  (error: any) => Promise.reject(error)
);
instance.interceptors.response.use(
  (response: AxiosResponse<any, any>) => response,
  (error: any) => {
    if (error.response.status === 403 && !toast.isActive("forbidden")) {
      toast({
        id: "forbidden",
        title: "Error",
        description: "You don't have permission to access this page",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }

    if (error.response.status === 401) {
      localStorage.removeItem(constants.STORAGE.ACCESS_TOKEN);

      window.location.href = "/auth/sign-in";
      return;
    }

    return Promise.reject(error);
  }
);

const token: string =
  localStorage.getItem(constants.STORAGE.ACCESS_TOKEN) || ("" as string);

if (token) instance.defaults.headers.common.Authorization = `Bearer ${token}`;
instance.defaults.headers.common.secret =
  "5ff3a258-2700-11ed-a261-0242ac120002";

const get = async (url: string, params?: any, options?: { isRawUrl?: boolean }): Promise<any> => {
  try {
    const config: any = { params };
    const { data: getData } = await instance.get(getUrlPrefix(options?.isRawUrl) + url, config);
    return getData;
  } catch (error) {
    return _errorHandler(error);
  }
};

const getPublic = async (url: string, params?: any): Promise<any> => {
  try {
    const config: any = { params };
    const { data: getData } = await instance.get("/" + url, config);
    return getData;
  } catch (error) {
    return _errorHandler(error);
  }
};

const put = async (url: string, data: any = {}, options?: { isRawUrl?: boolean }): Promise<any> => {
  try {
    let response: any = {};
    if (data.toLocaleString() === "[object FormData]") {
      response = await instance.put(getUrlPrefix(options?.isRawUrl) + url, data);
    } else {
      response = await instance.put(getUrlPrefix(options?.isRawUrl) + url, data, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });
    }
    return response.data;
  } catch (error) {
    _errorHandler(error);
  }
};

const post = async (url: string, data: any = {}, options?: { isRawUrl?: boolean }): Promise<any> => {
  try {
    const { data: postData }: any = await instance.post(
      getUrlPrefix(options?.isRawUrl) + url,
      data
    );
    return postData;
  } catch (error) {
    _errorHandler(error);
  }
};

const patch = async (url: string, data: any = {}, options?: { isRawUrl?: boolean }): Promise<any> => {
  try {
    const { data: postData }: any = await instance.patch(
      getUrlPrefix(options?.isRawUrl) + url,
      data
    );
    return postData;
  } catch (error) {
    _errorHandler(error);
  }
};

const del = async (url: string, data: any = {}, options?: { isRawUrl?: boolean }): Promise<any> => {
  try {
    const { data: delData }: any = await instance.delete(
      getUrlPrefix(options?.isRawUrl) + url,
      {
        data,
      }
    );
    return delData;
  } catch (error) {
    _errorHandler(error);
  }
};
const _errorHandler = (err: any) => {
  if (axios.isAxiosError(err)) {
    return err.message;
  } else {
    if (err.response && err.response.status === 401) {
      // todo
    }
    throw err;
  }
};

export { get, post, del, put, patch, getPublic, instance };
