import { ChainIds, ChainNames } from "configs/chain";
import Decimal from "decimal.js";

export const subAddress = (address: string) => {
  const before: string = address?.substr(0, 6);
  const after: string = address?.substr(-4);
  return `${before}...${after}`;
};

export const formatNumber = (number: number) => {
  const unitlist = ["", "K", "M", "B"];
  let sign = Math.sign(number);
  let unit = 0;
  while (Math.abs(number) > 1000) {
    unit = unit + 1;
    number = Math.floor(Math.abs(number) / 100) / 10;
  }
  if (!unitlist[unit]) return +(sign * Math.abs(number)).toFixed(4);
  return sign * Math.abs(number) + unitlist[unit];
};

export const appCount = (number: number) =>
  new Intl.NumberFormat().format(number);
/**
 * x.xx common number
 * 0.xxxxx small number
 * 0.0_p_xxxx very small number
 */
export const formatPriceValue = (numberStr: string, decimalPlaces = 8) => {
  numberStr = Number(numberStr).toString();
  // Case 0.0000000022578596055137 => 2.2578596055137e-9
  // Very small number.
  // Return 0.0_7_22579.
  if (numberStr.includes("e-")) {
    const [value, pow] = numberStr.split("e-");
    const powDecimal = new Decimal(pow).sub("1");

    const valueDecimal = new Decimal(Number(value).toFixed(3).replace(".", ""));

    // 0.0000000022578596055137 => 0.0_7_22579
    const result = `0.0_${powDecimal}_${valueDecimal}`;
    return result;
  }

  // 1.12139812739 => 1.12;
  if (+numberStr > 1) return new Decimal(numberStr).toFixed(2);

  // 0.01283470198 => 0.01283
  return new Decimal(numberStr).toFixed(decimalPlaces);
};

export const separateNumber = (number: number, separate = ",") => {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, separate);
};

export const convertEnumToArr = (StringEnum: any) =>
  Object.keys(StringEnum).map((name) => ({
    name,
    value: StringEnum[name as keyof typeof StringEnum],
  }));

export const generateTokenLink = (
  chainId: string | number,
  address: string
) => {
  const chain = {
    [ChainIds.Bsc]: ChainNames.Bsc,
    [ChainIds.Ethereum]: ChainNames.Ethereum,
    [ChainIds.Arb]: ChainNames.Arbitrum,
    [ChainIds.Pol]: ChainNames.Polygon,
    [ChainIds.Pul]: ChainNames.Pulse,
    [ChainIds.Bit]: ChainNames.Bitrock,
    [ChainIds.Shi]: ChainNames.Shibariym,
    [ChainIds.Cyb]: ChainNames.Cybria,
    [ChainIds.Sol]: ChainNames.Solana,
    [ChainIds.Base]: ChainNames.Base,
  };

  const chainPath = (chain as any)[String(chainId)];
  return `https://dexview.com/${chainPath}/${address}`;
};
