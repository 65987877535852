import {
  Box,
  Button,
  Flex,
  Input,
  InputGroup,
  InputLeftElement,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  useDisclosure,
  Select,
  HStack,
  Text,
  IconButton,
  useToast,
  Spinner,
  Center,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  ModalFooter,
} from "@chakra-ui/react";
import { SearchIcon } from "@chakra-ui/icons";
import { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { getMintConfigs, MintConfig } from "utils/apiService/BabyToken";
import { subAddress } from "utils/helpers";
import { getTimeAgo } from "./pages/baby-token-detail";
import { useQuery } from "@tanstack/react-query";

enum Status {
  PENDING = 0,
  ACTIVE = 1,
  JUPITER_DELISTED = 2,
  NOT_EXIST = 3,
  BLACKLISTED = 4,
}

const BabyToken = () => {
  const history = useHistory();
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedConfig, setSelectedConfig] = useState<MintConfig | null>(null);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [status, setStatus] = useState<string>(String(Status.ACTIVE));

  const {
    data: mintConfigsData,
    isLoading,
    error,
  } = useQuery(
    ["mintConfigs", page, search, status],
    () =>
      getMintConfigs({
        page,
        limit: 20,
        search,
        status,
      }),
    {
      keepPreviousData: true,
      onError: () => {
        toast({
          title: "Error",
          description: "Failed to fetch mint configs",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      },
    }
  );

  const mintConfigs = mintConfigsData?.docs || [];
  const totalPages = mintConfigsData?.totalPages || 1;

  const handleViewDetails = (mint: string) => {
    history.push(`/admin/baby-token-detail/${mint}`);
  };

  const handleCopy = (text: string) => {
    navigator.clipboard.writeText(text);
    toast({
      title: "Copied",
      description: "Address copied to clipboard",
      status: "success",
      duration: 2000,
      isClosable: true,
    });
  };

  const handleViewJson = (config: MintConfig) => {
    setSelectedConfig(config);
    onOpen();
  };

  const getStatusLabel = (status: number) => {
    switch (status) {
      case Status.PENDING:
        return "Pending";
      case Status.ACTIVE:
        return "Active";
      case Status.JUPITER_DELISTED:
        return "Jupiter Delisted";
      case Status.NOT_EXIST:
        return "Not Exist";
      case Status.BLACKLISTED:
        return "Blacklisted";
      default:
        return "Unknown";
    }
  };

  console.log(mintConfigs);

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <Flex
        mb="20px"
        flexDirection={{ base: "column", md: "row" }}
        justifyContent="space-between"
      >
        <HStack spacing={4} mb={{ base: 4, md: 0 }}>
          <InputGroup maxW="300px">
            <InputLeftElement pointerEvents="none">
              <SearchIcon color="gray.300" />
            </InputLeftElement>
            <Input
              placeholder="Search by mint or name"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </InputGroup>
          <Select
            placeholder="All Status"
            value={status}
            onChange={(e) => setStatus(e.target.value)}
            maxW="200px"
          >
            <option value={Status.PENDING}>Pending</option>
            <option value={Status.ACTIVE}>Active</option>
            <option value={Status.JUPITER_DELISTED}>Jupiter Delisted</option>
            <option value={Status.NOT_EXIST}>Not Exist</option>
            <option value={Status.BLACKLISTED}>Blacklisted</option>
          </Select>
        </HStack>
      </Flex>

      <Box overflowX="auto">
        {isLoading ? (
          <Center py={10}>
            <Spinner size="xl" />
          </Center>
        ) : !mintConfigs ? (
          <Center py={10}>
            <Text>No data found</Text>
          </Center>
        ) : (
          <Table variant="simple">
            <Thead>
              <Tr>
                <Th>Mint</Th>
                <Th>Symbol</Th>
                <Th>Creator</Th>
                <Th>Status</Th>
                <Th>Rewards</Th>
                <Th>24h Distribution</Th>
                <Th>24h Harvest</Th>
                <Th>Created At</Th>
                <Th>Last Transfer</Th>
                <Th>Actions</Th>
              </Tr>
            </Thead>
            <Tbody>
              {mintConfigs.map((config: any) => (
                <Tr key={config._id}>
                  <Td
                    cursor="copy"
                    onClick={() => handleCopy(config.mint)}
                    _hover={{ color: "blue.500" }}
                  >
                    {subAddress(config.mint)}
                  </Td>
                  <Td>{config.symbol}</Td>
                  <Td
                    cursor="copy"
                    onClick={() => handleCopy(config.creator)}
                    _hover={{ color: "blue.500" }}
                  >
                    {subAddress(config.creator)}
                  </Td>
                  <Td>
                    <Text
                      color={
                        config.status === Status.PENDING
                          ? "orange.500"
                          : "inherit"
                      }
                    >
                      {getStatusLabel(config.status)}
                    </Text>
                  </Td>
                  <Td>
                    <Text
                      color={
                        Number(
                          config.distributionTracker?.totalAccumulatedRewards ||
                            0
                        ) > 0
                          ? "green.500"
                          : "inherit"
                      }
                    >
                      {
                        +Number(
                          Number(
                            config.distributionTracker
                              ?.totalAccumulatedRewards || 0
                          ) /
                            10 ** 9
                        ).toFixed(2)
                      }{" "}
                      SOL
                    </Text>
                  </Td>
                  <Td>
                    <Box fontSize="sm">
                      <Text>
                        Total: {config.distributionMetrics?.["24h"]?.total || 0}
                      </Text>
                      <Text color="orange.500">
                        Pending:{" "}
                        {config.distributionMetrics?.["24h"]?.pending || 0}
                      </Text>
                      <Text color="green.500">
                        Completed:{" "}
                        {config.distributionMetrics?.["24h"]?.completed || 0}
                      </Text>
                      <Text color="red.500">
                        Failed:{" "}
                        {config.distributionMetrics?.["24h"]?.failed || 0}
                      </Text>
                      <Text color="blue.500">
                        Rewards:{" "}
                        {config.distributionMetrics?.["24h"]?.rewards || 0}
                      </Text>
                    </Box>
                  </Td>
                  <Td>
                    <Box fontSize="sm">
                      <Text>
                        Total: {config.harvestMetrics?.["24h"]?.total || 0}
                      </Text>
                      <Text color="orange.500">
                        Pending: {config.harvestMetrics?.["24h"]?.pending || 0}
                      </Text>
                      <Text color="green.500">
                        Completed:{" "}
                        {config.harvestMetrics?.["24h"]?.completed || 0}
                      </Text>
                      <Text color="red.500">
                        Failed: {config.harvestMetrics?.["24h"]?.failed || 0}
                      </Text>
                    </Box>
                  </Td>
                  <Td>
                    {getTimeAgo(new Date(config.createdAt).getTime())}
                    <Text fontSize="sm" color="gray.500">
                      {new Date(config.createdAt).toLocaleString()}
                    </Text>
                  </Td>
                  <Td>
                    {config.lastTransferAt ? (
                      <>
                        {getTimeAgo(config.lastTransferAt * 1000)}
                        <Text fontSize="sm" color="gray.500">
                          {new Date(
                            config.lastTransferAt * 1000
                          ).toLocaleString()}
                        </Text>
                      </>
                    ) : (
                      "N/A"
                    )}
                  </Td>
                  <Td>
                    <HStack spacing={2}>
                      <Button
                        size="sm"
                        colorScheme="blue"
                        onClick={() => handleViewDetails(config.mint)}
                      >
                        View Details
                      </Button>
                      <Button
                        size="sm"
                        colorScheme="green"
                        onClick={() => handleViewJson(config)}
                      >
                        View JSON
                      </Button>
                    </HStack>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        )}
      </Box>

      {!isLoading && mintConfigs && mintConfigs.length > 0 && (
        <Flex justify="space-between" align="center" mt={4}>
          <Text>
            Page {page} of {totalPages}
          </Text>
          <HStack>
            <Button
              onClick={() => setPage((p) => Math.max(1, p - 1))}
              isDisabled={page === 1}
            >
              Previous
            </Button>
            <Button
              onClick={() => setPage((p) => Math.min(totalPages, p + 1))}
              isDisabled={page === totalPages}
            >
              Next
            </Button>
          </HStack>
        </Flex>
      )}

      <Modal isOpen={isOpen} onClose={onClose} size="2xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Raw JSON Data</ModalHeader>
          <ModalCloseButton />
          <ModalBody fontSize="sm">
            <Box
              p={4}
              bg="gray.50"
              borderRadius="md"
              maxH="70vh"
              overflowY="auto"
            >
              <pre style={{ whiteSpace: "pre-wrap", wordWrap: "break-word" }}>
                {JSON.stringify(selectedConfig, null, 2)}
              </pre>
            </Box>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={onClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default BabyToken;
